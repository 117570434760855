.job {
  @apply flex flex-col gap-5;

  @media only screen and (min-width: 601px) {
    @apply mb-20;

    width: 80%;

    .project-content {
      flex-grow: 1;
      flex-basis: 75em;
    }

    h3 {
      @apply text-3xl;
    }
  }

  @media only screen and (max-width: 600px) {
    @apply mb-16;

    h3 {
      @apply text-2xl;
    }
  }

  .site-button {
    @apply pt-2 pb-2 pl-8 pr-8 text-base;
  }
}
