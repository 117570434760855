.contact-card {
  @apply flex flex-col p-6 rounded-3xl shadow-md;

  background-image: linear-gradient(to bottom, #0b2959, #000000);
  border: #0b2959 5px solid;

  @media only screen and (max-width: 600px) {
    @apply p-6;

    .card-content {
      @apply flex-col;

      .image img {
        @apply ml-auto mr-auto;

        width: 12rem !important;
      }
    }

    .links .link {
      @apply text-sm;
    }
  }

  @media only screen and (min-width: 601px) {
    .card-content {
      .info {
        @apply pl-4;

        margin-top: 0 !important;
      }
    }
  }

  .card-content {
    @apply flex gap-4;

    .image img {
      @apply rounded-3xl mb-2 w-60;

      border: #0b2959 5px solid;

      &.none {
        @apply border-none rounded-none;
      }
    }

    .info {
      @apply w-full flex flex-col gap-4 justify-center text-center;

      .name {
        @apply text-center text-3xl;
      }

      .title {
        @apply font-semibold text-center;
      }

      .description {
        @apply text-left;
      }
    }
  }

  .links {
    @apply flex flex-row mt-6;

    .link {
      @apply flex flex-row gap-1 items-center m-auto;

      .svg-inline--fa {
        @apply mr-1;
      }
    }
  }
}
