.project {
  @apply flex items-center text-left;

  @media only screen and (min-width: 601px) {
    @apply mb-16;

    .project-image {
      @apply flex items-center aspect-square;

      flex-grow: 1;
      flex-basis: 20em;

      img {
        max-width: 300px;
        width: 100%;
      }
    }

    .project-content {
      flex-grow: 2;
      flex-basis: 75em;
    }

    &:nth-child(odd) {
      @apply flex-row-reverse;

      .project-image {
        @apply ml-12;
      }
    }

    &:nth-child(even) {
      .project-image {
        @apply mr-12;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    @apply flex-col mb-16;

    .project-image {
      @apply mb-5;

      width: 60%;

      img {
        width: 100%;
      }
    }

    .project-links {
      flex-direction: column !important;

      .site-button {
        @apply w-full;
      }
    }
  }

  h3 {
    @apply text-4xl;
  }

  .project-content {
    @apply flex flex-col space-y-5;
  }

  .project-links {
    @apply flex flex-grow flex-row gap-5;
  }
}
