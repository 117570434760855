.nav-toggle {
  @apply float-right -mt-1 z-30 relative;
}

.nav-overlay {
  @apply fixed top-0 right-full w-full h-screen z-20;
  transition: all 300ms;

  &.nav-open {
    @apply block visible right-0;
  }

  .mobile-nav {
    @apply bg-black 
    h-full 
    pt-28 
    mr-0
    space-y-6;

    .link-icon {
      @apply inline-block w-7;
    }
  }

  .mobile-nav-section {
    @apply block 
    px-8
    space-y-3
    text-gray-400;

    label {
      @apply font-bold;
    }

    ul {
      @apply space-y-2;
    }

    a {
      @apply text-lg;
    }

    // add styling to active page link
    [aria-current]:not([aria-current="false"]) {
      @apply text-amber-500;
    }
  }
}

@media only screen and (max-width: 600px) {
  .nav-toggle {
    @apply block;
  }
  .mobile-nav {
    @apply block;
  }
}
@media only screen and (min-width: 601px) {
  .nav-toggle {
    @apply hidden;
  }
  .mobile-nav {
    @apply hidden;
  }
}

.nav-toggle {
  .hamburger {
    @apply justify-items-center z-50;
    height: 30px;
    display: -ms-grid;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
  }

  .hamburger div {
    background-color: white;
    position: relative;
    width: 30px;
    height: 2px;
    margin-top: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  #hamburger-checkbox {
    display: none;
  }

  #hamburger-checkbox:checked + .hamburger .top {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-top: 13px;
  }

  #hamburger-checkbox:checked + .hamburger .meat {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -2px;
  }

  #hamburger-checkbox:checked + .hamburger .bottom {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  #hamburger-checkbox:checked ~ .menu1 {
    height: 340px;
  }
}
