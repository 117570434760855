.home-intro {
  @apply flex w-full mb-16;

  .intro-content {
    @apply flex
      flex-col
      justify-center
      items-start;

    flex-grow: 1;
    width: 50%;

    .inner {
      @apply flex
        flex-col
        space-y-8;
    }
  }

  .intro-image {
    @apply aspect-square;

    width: 50%;
  }

  @media only screen and (max-width: 600px) {
    @apply flex-col flex-col-reverse mb-12;

    .intro-content {
      @apply items-center w-full;

      .inner {
        @apply space-y-5 items-center text-center w-full;

        h1 {
          @apply text-4xl;
        }
        p {
          @apply font-semibold italic;
        }
      }
    }

    .intro-image {
      @apply w-full mx-auto;
      max-width: 400px;
    }
  }

  @media only screen and (min-width: 601px) {
    .intro-content {
      h1 {
        @apply text-7xl;
      }
      p {
        @apply text-2xl font-semibold italic;
      }
    }
  }
}

.about-intro {
  @apply text-center;

  .inner-flex.inner {
    @apply max-w-5xl space-y-6;
  }

  @media only screen and (min-width: 601px) {
    h2 {
      @apply text-5xl;
    }
  }
}

.featured {
  @apply pb-20;

  .feature {
    @apply text-center;

    .icon {
      @apply mb-4;
    }

    h3 {
      @apply text-2xl mb-2;
    }
  }

  @media only screen and (max-width: 600px) {
    .columns {
      @apply grid grid-flow-row gap-16;
    }
  }

  @media only screen and (min-width: 601px) {
    .columns {
      @apply grid grid-cols-3;

      .feature {
        @apply px-5;
      }
    }
  }
}
