.blink-header {
  @apply h-12 mt-8 mb-12;

  .logo {
    @apply z-50;

    position: absolute;
  }

  @media only screen and (max-width: 600px) {
    & {
      @apply mb-4;
    }

    .logo {
      width: 50%;
    }
  }
}
