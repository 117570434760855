.projects-intro {
  @apply text-left;

  .inner {
    @apply w-full max-w-7xl;
  }

  .inner-flex {
    @apply flex flex-col gap-8;
  }

  @media only screen and (min-width: 601px) {
    h2 {
      @apply text-5xl mt-8;
    }

    p {
      @apply max-w-3xl text-lg;
    }
  }

  @media only screen and (max-width: 600px) {
    h2 {
      @apply text-3xl;
    }

    p {
      @apply text-lg;
    }
  }
}

.projects {
  @apply text-center;

  h2 {
    @apply text-4xl mb-8;
  }

  .inner {
    @apply flex w-full flex-col max-w-5xl;
  }

  @media only screen and (min-width: 601px) {
    h2 {
      @apply pb-6;
    }
  }
}
