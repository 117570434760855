.downloads-intro {
  @apply flex text-center;

  .inner {
    @apply w-full max-w-5xl space-y-4;
  }

  @media only screen and (min-width: 601px) {
    h2 {
      @apply text-5xl mt-8;
    }
  }

  @media only screen and (max-width: 600px) {
    @apply text-left;

    h2 {
      @apply text-3xl;
    }
  }
}

.downloads {
  @apply flex justify-center text-center py-8;

  .inner {
    @apply grid grid-cols-2 gap-8 py-4 max-w-5xl;

    @media only screen and (max-width: 600px) {
      @apply flex-col grid-cols-none grid-flow-row;
    }

    .repo {
      @apply flex flex-col space-y-4 rounded-2xl p-5;

      background-image: linear-gradient(to bottom, #082046, #000000);

      @media only screen and (max-width: 600px) {
        background-image: linear-gradient(to bottom, #0b2959, #000000);
      }

      header {
        @apply flex flex-col flex-grow space-y-2 text-left;

        .heading {
          @apply text-3xl;

          .repo-link {
            @apply float-right;
          }
        }

        .version {
          color: #6f95d3;
        }

        .desc {
          @apply flex-grow;
        }
      }

      .platform-downloads {
        @apply flex flex-col space-y-3;

        .site-button {
          @apply w-full;
        }
      }

      .other-downloads {
        @apply flex flex-col space-y-2 rounded-2xl p-5 text-white;

        background-image: linear-gradient(to bottom, #0b2959, #000000);
        border: #0b2959 5px solid;

        h3 {
          @apply mb-2;
        }

        a {
          @apply text-left;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .inner .repo header .heading {
      @apply text-2xl;
    }
  }
}
