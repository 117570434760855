.about {
  .inner {
    @apply flex flex-row items-start space-x-12;

    .content {
      @apply space-y-6;
    }

    img {
      @apply object-contain drop-shadow-lg;

      min-width: 42%;
    }

    .intro {
      @apply grid grid-cols-2 gap-10;
    }

    @media only screen and (max-width: 600px) {
      @apply flex-col space-x-0 space-y-12;

      img {
        width: 100%;
      }

      .intro {
        @apply flex flex-row flex-wrap space-x-0;
      }
    }
  }

  @media only screen and (min-width: 601px) {
    h2 {
      @apply text-4xl;
    }
  }
}

.team {
  .inner {
    @apply flex flex-col items-center;
    .content {
      @apply space-y-6 text-center max-w-5xl;
    }
  }

  @media only screen and (min-width: 601px) {
    h2 {
      @apply text-4xl;
    }
  }

  @media only screen and (max-width: 600px) {
    .inner .content {
      @apply text-left;
    }
  }
}

.contact-cards {
  @apply grid grid-cols-2 gap-10 pt-12 py-8;

  @media only screen and (max-width: 600px) {
    @apply flex-col grid-cols-1 w-full;
  }
}
