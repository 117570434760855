footer {
  @apply w-full;

  &.border {
    border-top: 40px solid transparent;
    border-image: url("/assets/border-wavy-solid-bottom@2x.png") 40 0 0 0 round;

    @media only screen and (max-width: 860px) {
      border-top: 20px solid transparent;
      border-image: url("/assets/border-wavy-solid-bottom.png") 20 0 0 0 round;
    }
  }

  .footer-content {
    @apply py-12 w-full;

    background-image: linear-gradient(to bottom, #0f3f8a, #0c254e 100%);

    .link-icon {
      @apply inline-block w-7;
    }

    @media only screen and (max-width: 600px) {
      @apply py-8;

      .columns {
        @apply flex flex-col space-y-4;

        .column {
          @apply relative;

          ul {
            @apply flex flex-col space-y-2 mb-4;

            li a {
              @apply font-semibold;
            }
          }
        }

        // after each div except the last one, add a horizontal line
        .column:not(:last-of-type)::after {
          @apply block absolute left-0 bottom-0 w-full;

          content: "";
          background-color: #3e71c3;
          border-radius: 1px;
          height: 2px;
        }
      }
    }

    @media only screen and (min-width: 601px) {
      .columns {
        @apply grid grid-cols-3 gap-1;

        .column {
          @apply relative pl-6;

          ul {
            @apply flex flex-col space-y-2;

            li a {
              @apply font-semibold;
            }
          }
        }

        // after each div except the last one, add a vertical line
        .column:not(:last-of-type)::after {
          @apply block absolute right-0 top-0 h-full;

          content: "";
          background-color: #3e71c3;
          border-radius: 1px;
          width: 2px;
        }
      }
    }
  }
}
