.desktop-nav {
  @apply float-right m-0 p-0 bottom-0;

  .desktop-nav-item {
    @apply float-left relative;

    button {
      @apply px-4 py-2 text-xl;

      &::after {
        @apply inline-block ml-3;
        width: 1.25rem;
        height: 0.625rem;
        content: "";
        -webkit-mask: url("/assets/drop-down-arrow.svg") no-repeat 50% 50%;
        mask: url("/assets/drop-down-arrow.svg") no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        transition: rotate 300ms;
        background-color: white;
      }

      &:hover::after {
        @apply bg-amber-500;
      }
    }

    &.drop-down-open button::after {
      rotate: 180deg;

      path {
        @apply fill-amber-500;
      }
    }

    .drop-down {
      @apply absolute mt-2 bg-black rounded-lg shadow-lg opacity-0 invisible;
      right: 0;
      z-index: 10;
      transition: all 300ms;

      .drop-down-item {
        @apply overflow-auto;

        a {
          @apply block px-8 py-3 text-gray-100 whitespace-nowrap border-b-stone-800 border-b-2;

          &:hover {
            @apply text-amber-500;
          }

          .link-icon {
            @apply inline-block w-7;
          }
        }

        // add styling to drop-down-open page link
        [aria-current]:not([aria-current="false"]) {
          @apply text-amber-500;
        }

        &:last-child a {
          @apply border-b-0;
        }
      }
    }

    &.drop-down-open .drop-down {
      @apply block visible opacity-100;
    }
  }
}

@media only screen and (max-width: 600px) {
  .desktop-nav {
    @apply hidden;
  }
}
