.jobs-intro {
  @apply text-left;

  .inner {
    @apply w-full max-w-5xl flex gap-12;

    .copy {
      @apply flex flex-col space-y-4 justify-center;
    }
  }

  @media only screen and (min-width: 601px) {
    h2 {
      @apply text-5xl;
    }

    p {
      @apply max-w-3xl;
    }

    .image {
      .jobs-image {
        @apply w-full max-w-lg;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .inner {
      @apply gap-6 flex-col-reverse;
    }

    h2 {
      @apply text-3xl;
    }

    .image {
      @apply flex justify-center;

      .jobs-image {
        width: 70%;
      }
    }
  }
}

.jobs {
  h2 {
    @apply text-4xl mb-8;
  }

  .inner {
    @apply flex w-full flex-col max-w-5xl;
  }

  @media only screen and (min-width: 601px) {
    h2 {
      @apply pb-6;
    }
  }
}
